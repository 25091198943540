(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var $mainNav = $('#main-nav'),
    $body = $('body'),
    $navbarToggler = $('.navbar-toggler'),
    $navbarCollapse = $('.navbar-collapse'),
    $staffSlick = $('.staff-list'),
    $videoList = $('.video-list'),
    $slickStaffImg = $('.slick-staff-img'),
    $videoLightbox = $('.video-lightbox'),
    $galleryItem = $('.gallery-list-item'),
    $masthead = $('.masthead'),
    mastheadHeight = $masthead.outerHeight(),
    $footerDate = $('.footer-date'),
    today = new Date(),
    year = today.getFullYear();
$footerDate.html(year);
AOS.init();
var scroll = window.scrollY; // initial scroll

if (scroll > mastheadHeight) {
  $mainNav.addClass('fixed-top');
} else {
  $mainNav.removeClass('fixed-top');
}

$(window).on('scroll', function (event) {
  scroll = window.scrollY;

  if (scroll > mastheadHeight) {
    $mainNav.addClass('fixed-top');
  } else {
    $mainNav.removeClass('fixed-top');
  }
});
$navbarCollapse.on('show.bs.collapse', function (ev) {
  $body.addClass('nav-is-open');
});
$navbarCollapse.on('hidden.bs.collapse', function (ev) {
  $body.removeClass('nav-is-open');
});
lightbox.option({
  albumLabel: 'Imagen %1 de %2'
}); // $.instagramFeed({
//   username: 'skydive_factory',
//   container: '#instagram-feed',
//   display_profile: true,
//   display_biography: false,
//   display_gallery: true,
//   callback: null,
//   styling: false,
//   items: 9,
//   items_per_row: 4,
//   margin: 1,
//   lazy_load: true,
//   on_error: console.error
// });

$videoLightbox.on('click', function (ev) {
  ev.preventDefault(); // videoLightboxInstance.show();

  basicLightbox.create("\n    <iframe width=\"1200\" height=\"675\" src=\"https://player.vimeo.com/video/".concat($(this).data('video-id'), "\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>\n  ")).show();
});
$(document).on('keydown', function (ev) {
  if (ev.keyCode == '27') {
    if (videoLightboxInstance !== undefined) {
      videoLightboxInstance.close();
    }
  }
});
$slickStaffImg.slick({
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  draggable: true,
  arrows: false,
  dots: true,
  slidesToScroll: 1,
  infinite: false,
  lazyLoad: 'ondemand'
});
$staffSlick.slick({
  slidesToShow: 3,
  draggable: true,
  arrows: false,
  dots: true,
  slidesToScroll: 1,
  infinite: false,
  responsive: [{
    breakpoint: 992,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: false,
      dots: true
    }
  }, {
    breakpoint: 576,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
      centerMode: true,
      centerPadding: '30px'
    }
  }]
});
$videoList.slick({
  slidesToShow: 1,
  draggable: true,
  arrows: false,
  dots: true,
  slidesToScroll: 1,
  infinite: true,
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000
}); // Select all links with hashes

$('.smooth-link').on('click', function (event) {
  // On-page links
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']'); // Does a scroll target exist?

    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000, function () {
        // Callback after animation
        // Must change focus!
        var $target = $(target);
        $target.focus();

        if ($target.is(":focus")) {
          // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable

          $target.focus(); // Set focus again
        }

        ;
      });
    }
  }
});
$('.nav-link').on('click', function (ev) {
  $('#navbarCollapse').collapse('hide');
});

var showVideoThumbnails = function showVideoThumbnails() {
  $videoLightbox.each(function (idx, el) {
    var videoID = $(el).data('video-id');
    var vimeoDataResponse = fetch("https://vimeo.com/api/v2/video/".concat(videoID, ".json")).then(function (res) {
      return res.json();
    }).then(function (data) {
      $(el).find('img').attr('src', data[0].thumbnail_large);
    });
  });
};

showVideoThumbnails();

},{}]},{},[1]);
